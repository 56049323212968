import React, { useState, useEffect } from "react";
import { useNavigate, Route, Link, useLocation } from "react-router-dom";
import Index from "./Pages/Index";
import WhoWeAre from "./Pages/WhoWeAre";
import Offering from "./Pages/Offering";
import Contact from "./Pages/Contact";
import CyprusOffice from "./Pages/CyprusOffice";
import PortugalOffice from "./Pages/PortugalOffice";
import Career from "./Pages/Career";
import SlideRoutes from 'react-slide-routes';
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { isMobile } from "react-device-detect";
import MobileHome from "./Components/MobileView/Home"
import MobileAbout from "./Components/MobileView/About";
import MobileOffering from "./Components/MobileView/Offering";
import MobileCyprusOffice from "./Components/MobileView/CyprusOffice";
import MobilePortugalOffice from "./Components/MobileView/PortugalOffice";
import MobileCareer from "./Components/MobileView/Career";
import MobileContact from "./Components/MobileView/Contact";

function AppRouter() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    let navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [isFirstCardOpen, setIsFirstCardOpen] = useState(false);
    const [isSecondCardOpen, setIsSecondCardOpen] = useState(false);

    useEffect(() => {
        setActiveStep(Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')));
    });

    const routes = {
        1: "/",
        2: "/about",
        3: "/offering",
        4: "/cyprus",
        5: "/portugal",
        6: "/career",
        7: "/contact"
    }

    function goToSlide(goToId) {
        if (goToId >= 1 && goToId <= 7) {
            navigate(routes[goToId]);
        }
    }

    function goToNextStep() {
        let nextSlideId = Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')) + 1;
        goToSlide(nextSlideId);
    }

    function goToPrevStep() {
        let nextSlideId = Number(document.getElementsByClassName('swiper-slide')[0].getAttribute('data-id')) - 1;
        goToSlide(nextSlideId);
    }

    function toggleCard(target) {
        if (target === "card1"){
            setIsFirstCardOpen(!isFirstCardOpen);
        } else {
            setIsSecondCardOpen(!isSecondCardOpen);
        }
    }

    if (isMobile) {
        return (
            <>
                <MobileHome/>
                <MobileAbout/>
                <MobileOffering
                    toggleCard = {toggleCard}
                    isFirstCardOpen={isFirstCardOpen}
                />
                <MobileCyprusOffice/>
                <MobilePortugalOffice/>
                <MobileCareer
                    toggleCard = {toggleCard}
                    isSecondCardOpen={isSecondCardOpen}
                />
                <MobileContact/>
            </>
        )
    }

    return (
        <ReactScrollWheelHandler
            upHandler={goToPrevStep}
            downHandler={goToNextStep}
            pauseListeners={(isFirstCardOpen || isSecondCardOpen )}
        >
            <div className={`stepper ${isFirstCardOpen || isSecondCardOpen ? 'hide' : 'show'}`}>
                {Object.entries(routes).map((route) => (
                    <Link
                        key={route[0]}
                        to={route[1]}
                        className={activeStep == route[0] ? 'active' : ''}
                    />
                ))}
            </div>
            <SlideRoutes
                animation="vertical-slide"
                timing="ease-in-out"
                duration={500}
            >
                <Route key={1} path="/" element={<Index sideImage={1}/>}/>
                <Route key={2} path="/about" element={<WhoWeAre sideImage={2}/>}/>
                <Route key={3} path="/offering" element={
                    <Offering
                        sideImage={3}
                        toggleCard={toggleCard}
                        isFirstCardOpen={isFirstCardOpen}
                    />}/>
                <Route key={4} path="/cyprus" element={<CyprusOffice sideImage={4}/>}/>
                <Route key={5} path="/portugal" element={<PortugalOffice sideImage={5}/>}/>
                <Route key={6} path="/career" element={
                    <Career
                        sideImage={6}
                        toggleCard={toggleCard}
                        isSecondCardOpen={isSecondCardOpen}
                    />}>
                </Route>
                <Route key={7} path="/contact" element={<Contact sideImage={7}/>}/>
            </SlideRoutes>
        </ReactScrollWheelHandler>
    )
}

export default AppRouter;
