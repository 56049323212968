import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../images/1_icon.svg';
import icon2 from '../images/2_icon.svg';
import icon3 from '../images/3_icon.svg';
import icon4 from '../images/4_icon.svg';
import icon5 from '../images/5_icon.svg';
import cross from '../images/cross.svg';

class Offering extends Component {

    componentWillUnmount() {
        if (this.props.isFirstCardOpen) {
            this.props.toggleCard("card1");
        }
    }

    render() {

        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div
                    className={`side_image side_image-${this.props.sideImage} ${this.props.isFirstCardOpen ? "side_image-opened-page" : null}`}/>
                <div className="offering-page page">
                    <div
                        className={`offering__content page__content visible-content ${!this.props.isFirstCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <div className="page_number">
                                /03
                            </div>
                            <h2>What we’re offering?</h2>
                            <div className="page_description">
                                <p>
                                    Data Mining and Data Analysis, Market Research, Product Research, Positioning,
                                    Customer
                                    Reviews,
                                    Sentiment Analysis, or any other issue for which data exists.
                                </p>
                                <span className="openServices info-btn"
                                      onClick={() => this.props.toggleCard("card1")}>[ Read more ]</span>
                            </div>
                            <Link to="/cyprus" className="btn">
                                <div className="front">Our offices</div>
                                <div className="back">Our offices</div>
                            </Link>
                        </div>
                        <div className={`services read-more ${this.props.isFirstCardOpen ? "show" : "hide"}`}>
                            <div className="services__container">
                                <h2>Our Services.</h2>
                                <div className="services__wrapper d-flex">
                                    <div className="services__item">
                                        <img src={icon1} alt="icon 1"/>
                                        <h5>Data Mining and Data Analysis</h5>
                                        <p>Data Mining and Data Analysis have been around for a long time. Both data
                                            mining and data analytics are essential to be performed perfectly. In
                                            whichever arena you move, you cannot deny the significance of both in a
                                            data-driven domain of the 21st century.</p>
                                    </div>
                                    <div className="services__item">
                                        <img src={icon2} alt="icon 2"/>
                                        <h5>Media Buying</h5>
                                        <p>Strategically purchased media can impact a campaign’s success. It’s not
                                            enough to have compelling copy and visuals – ads must be placed in the right
                                            locations and at the right times and frequencies, so that the right
                                            audiences see the ad.</p>
                                    </div>
                                    <div className="services__item">
                                        <img src={icon3} alt="icon 3"/>
                                        <h5>Website Design and Frontend Development</h5>
                                        <p>We will help you stand out from the crowd by creating responsive web
                                            applications and sites using the most robust technologies in the industry to
                                            help you boost your business.</p>
                                    </div>
                                    <div className="services__item">
                                        <img src={icon4} alt="icon 4"/>
                                        <h5>Marketing and Promotion strategies</h5>
                                        <p>A promotion strategy is key for positioning your brand on the market, making
                                            people aware of the products or services you offer. Whether you are aiming
                                            to grow your business to new audiences or retaining your existing customers,
                                            we help you to find the right approach.</p>
                                    </div>
                                    <div className="services__item">
                                        <img src={icon5} alt="icon 5"/>
                                        <h5>Customer Support</h5>
                                        <p>Our multilingual team can answer your customers’ phone calls, e-mails or live
                                            chat. We can answer their questions and address their concerns - or do
                                            upsales based on the overall strategy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="close_button" onClick={() => this.props.toggleCard("card1")}>
                                <img src={cross} alt="cross"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Offering;
