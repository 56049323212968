import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class CyprusOffice extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="cyprus_office-page page">
                    <div className="cyprus_office__content page__content visible-content">
                        <div className="page_number">
                            /04
                        </div>
                        <h2>Our Cyprus office.</h2>
                        <div className="page_description">
                            <p>
                                Our headquarters is based in Cyprus, a beautiful island with 320 sunny days a year. We
                                chose
                                Limassol city for the office location because it is the most dynamic place in Cyprus
                                attracting
                                people from all over the world and also allows you to chill out at the nearest beaches
                                and
                                seaside.<br/>
                                30 people were united in the heart of Limassol to accomplish ambitious projects and
                                share the
                                same passion for creating great things.
                            </p>
                        </div>
                        <Link to="/portugal" className="btn">
                            <div className="front">Our portugal office</div>
                            <div className="back">Our portugal office</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default CyprusOffice;
