import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import menuOpened from './Header'
import Modal from "react-bootstrap/Modal";
import logo from "../images/top_logo.svg";
import arrow from "../images/back.svg";

class Footer extends Component {

    render() {

        const currentYear = new Date().getFullYear();

        return (
            <>
                <footer className={`footer showing ${!menuOpened ? "header__footer" : ""}`}>
                    <Container fluid>
                        <span className="modalButton" onClick={this.props.openLegalMentions}>Legal Mentions</span>
                        <span className={"legal-text"}>tlfe.com {currentYear}. | All rights reserved.</span>
                    </Container>
                </footer>
                <Modal
                    show={this.props.showLegalMentions}
                    onHide={this.props.openLegalMentions}
                    fullscreen={true}
                    animation={true}
                    centered
                    scrollable
                >
                    <Modal.Header className="modal-desktop">
                        <img
                            src={logo}
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                        <button type="button" className="button-close" onClick={this.props.openLegalMentions}
                                aria-label="Close">
                            <span className="button-close-icon"/>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-mobile">
                            <div className="modal__header">
                                <div className="back-btn" onClick={this.props.openLegalMentions} aria-label="Close"><img
                                    src={arrow} alt="arrow"/> Back
                                </div>
                            </div>
                        </div>
                        <h2 className="title-72">Terms of Use.</h2>
                        <p>
                            This website (the “<span>Website</span>”) has been developed to assist you (“<span>you</span>”, “<span>your</span>”) to obtain the information you need about TLFE Website and TLFE MNG LTD (“<span>TLFE</span>” or “<span>we</span>” or “<span>us</span>” or “<span>our</span>”).
                        </p>
                        <p>
                            TLFE MNG LTD is a company registered in Cyprus with registration no HE 400279, and a registered office at Gladstonos & 1 Evangelistrias, AGATHANGELOU BUSINESS CENTER, 1st floor, 3032, Limassol, Cyprus.
                        </p>
                        <p>
                            This Website and any links from it to third-party sites have been established as an information source only and with a view to promoting TLFE’s services.
                        </p>
                        <p>
                            The materials on the https://www.tlfe.com/ website are provided on an “as is” basis and TLFE does not guarantee or makes any representation with respect to the accuracy, likely results, or reliability of the use of the materials on the Website, or otherwise relating to such materials. TLFE makes no warranties, expressed or implied, and hereby disclaims all warranties, with respect to the use of the materials and any other aspect related to this Website including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose or non-infringement of intellectual property or other violations of rights.
                        </p>
                        <p>
                            You should be aware that all third party websites linked to our website (“<span>Third Party Website(s)</span>”), are owned and operated by an independent party over which TLFE has no control. Any use of the Third Party Website will be subject to the terms and conditions of such Third Party Website.
                        </p>
                        <p>
                            TLFE and its affiliates are not in any way associated with the owner or operator of any Third Party Website or responsible or liable for the goods and services offered by them or for anything in connection with such Third Party Website. TLFE does not endorse or approve, and makes no warranties, representations or undertakings relating to the content of the Third Party Website.
                        </p>
                        <p>
                            In addition to the terms stated herein, TLFE disclaims liability for any loss, damage and any other consequence resulting directly or indirectly from or relating to your access and use of the Third Party Website or any information that you may provide or any transaction conducted on or via the Third Party Web site or the failure of any information, goods or services posted or offered at the Third Party Website or any error, omission or misrepresentation on the Third Party Website or any computer virus arising from or system failure associated with the Third Party Website.
                        </p>
                        <p>
                            If you have any questions in relation to the site or would like more information about TLFE, contact us at <a href="mailto:contact@tlfe.com">contact@tlfe.com</a>.
                        </p>
                        <p>
                            TLFE may revise these Terms of Use at any time without prior notice. By using this Website you are agreeing to be bound by the then current version of the Terms of Use.
                        </p>
                        <p>
                            These Terms of Use are governed and construed in accordance with the laws of England and Wales, and you irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of London, England.
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default Footer;
