import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Index extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="home-page page">
                    <div className="home-page__content page__content visible-content">
                        <div className="page_number">
                            /01
                        </div>
                        <h2>It's time to make a difference.</h2>
                        <div className="page_description">
                            <p>
                                We are not only just solving your problems of the day.<br/>
                                But also helping you see opportunities of tomorrow to take your business to the next
                                level.
                            </p>
                        </div>
                        <Link to="/about" className="btn">
                            <div className="front">Who we are</div>
                            <div className="back">Who we are</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
