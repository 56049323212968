import React, { Component } from 'react';
import arrow from "../../images/back.svg";
import { HashLink as Link } from "react-router-hash-link";

class Career extends Component {

    componentWillUnmount() {
        if (this.props.isSecondCardOpen) {
            this.props.toggleCard("card2");
        }
        const newParent = document.body;
        const bambooResult = document.getElementById('BambooHR');
        newParent.append(bambooResult);
        document.getElementById('BambooHR').setAttribute('style', 'display: none');
    }

    componentDidMount() {
        const newParent = document.getElementById('bamboo-target');
        const bambooResult = document.getElementById('BambooHR');
        newParent.prepend(bambooResult);
        document.getElementById('BambooHR').removeAttribute('style');
        setTimeout(() => {
            const bambooLinks = document.querySelectorAll(".BambooHR-ATS-Jobs-Item a");
            bambooLinks.forEach((link) => {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            });
        }, 2000);
    }

    render() {
        return (
            <div id="6" data-id="6"
                 className={`swiper-slide ${this.props.isSecondCardOpen ? "add-index" : null}`}>
                <div
                    className={"side_image side_image-6"}>
                    <div className="page_number">
                        /06
                    </div>
                </div>
                <div className="career-page page">
                    <div
                        className={`career__content page__content visible-content ${!this.props.isSecondCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <h2>We’re hiring!</h2>
                            <div className="page_description">
                                <p>
                                    Looking for a new challenge in your career?<br/>
                                    Have a look at our current opportunities in trendy Lisbon and sunny Cyprus,
                                    and join our growing team.
                                </p>
                            </div>
                            <span className="openServices btn" onClick={() => this.props.toggleCard("card2")}>Discover open positions</span>
                        </div>
                        <div className={`positions read-more ${this.props.isSecondCardOpen ? "show" : "hide"}`}>
                            <div className="modal__header">
                                <div className="back-btn" onClick={() => this.props.toggleCard("card2")}><img
                                    src={arrow} alt="arrow"/> Back
                                </div>
                            </div>
                            <div id="bamboo-target" className="positions__container">
                                <Link to="/contact#7" className="btn" onClick={() => this.props.toggleCard("card2")}>
                                    <div>Contact us</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Career;
