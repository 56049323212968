import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class WhoWeAre extends Component {

    render() {

        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="whoweare-page page">
                    <div className="whoweare__content page__content visible-content">
                        <div className="page_number">
                            /02
                        </div>
                        <h2>Who we are?</h2>
                        <div className="page_description">
                            <p>
                                TLFE are the leaders in offering companies online services in Marketing and Advertising,
                                Customer
                                Support, Customer Retention and Data Analysis.
                            </p>
                            <p>
                                We offer our clients a range of interactive marketing solutions that combine Data
                                Management and
                                Analytics using various metrics to measure the performance of the different initiatives.
                            </p>
                            <p>
                                We work closely with our clients to create targeted content, increase website traffic
                                and gain
                                new customers the most effective way.
                            </p>
                        </div>
                        <Link to="/offering" className="btn">
                            <div className="front">What we're offering</div>
                            <div className="back">What we're offering</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhoWeAre;
