import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PortugalOffice extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="portugal_office-page page">
                    <div className="portugal_office__content page__content visible-content">
                        <div className="page_number">
                            /05
                        </div>
                        <h2>Our Portugal office.</h2>
                        <div className="page_description">
                            <p>
                                Our Lisbon “Europe’s sunniest Capital” Office (in the roundskirts of Lisbon -
                                Carnaxide), with
                                more than 1000 m2 it’s a dynamic and stimulating workplace that reflects the spirit of
                                our
                                company.<br/>
                                A modern office, with excellent conditions to work in, a great environment and includes
                                extra
                                games for boosting your energy or relaxing when you need to zone out. We are almost 50
                                working
                                at our beautiful office!
                            </p>
                        </div>
                        <Link to="/career" className="btn">
                            <div className="front">We’re hiring</div>
                            <div className="back">We’re hiring</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default PortugalOffice;
