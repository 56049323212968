import React, {useState} from "react";
import Header from "./Components/Header";
import AppRouter from "./Router";
import Footer from "./Components/Footer";

function App() {

    const [showLegalMentions, setShowLegalMentions] = useState(false);

    const openLegalMentions = () => {
        setShowLegalMentions(!showLegalMentions);
    }

    return (
        <>
            <Header
                openLegalMentions={openLegalMentions}
            />
            <AppRouter/>
            <Footer
                openLegalMentions={openLegalMentions}
                showLegalMentions={showLegalMentions}
            />
        </>
    );
}

export default App;
