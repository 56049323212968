import React, {Component} from 'react';
import {Navbar, Container} from 'react-bootstrap';
import logo from '../images/top_logo.svg'
import {NavHashLink as NavLink, HashLink as Link} from "react-router-hash-link";
import {BrowserView, MobileView} from "react-device-detect";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {menuOpened: false};
    }

    handleMenu(path) {
        this.setState({menuOpened: !this.state.menuOpened});
        if(typeof path !== 'undefined') {
            document.getElementById(path).scrollIntoView({ 
                behavior: 'smooth' 
            });
        }
    }

    closeMenu() {
        this.setState({menuOpened: false})
    }

    render() {

        return (
            <Navbar collapseOnSelect expand="xxxl" id="navigaton" variant="dark">
                <Container fluid>
                    <div className="empty"/>
                    <Link to="/#top" onClick={() => this.closeMenu()} className={`logo ${!this.state.menuOpened ? "nav-logo" : ""}`}>
                        <img
                            src={logo}
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Link>
                    <button type="button" className={`navbar-toggler ${!this.state.menuOpened ? "collapsed" : ""}`}
                            onClick={() => this.handleMenu()}>
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <Navbar.Collapse id="basic-navbar-nav" className={`${this.state.menuOpened ? "show" : ""}`}>
                        <BrowserView className="d-flex navbar__wrapper">
                            <Container className={"justify-content-start"}>
                                <NavLink to="/#top" className="nav-link home" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/01</span>Home</NavLink>
                                <NavLink to="/about#2" className="nav-link about" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/02</span>Who we
                                    are</NavLink>
                                <NavLink to="/offering#3" className="nav-link offering" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/03</span>What
                                    We're Offering</NavLink>
                                <NavLink to="/cyprus#4" className="nav-link cyprus" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/04</span>Our
                                    Cyprus Office</NavLink>
                            </Container>
                            <Container className={"justify-content-start"}>
                                <NavLink to="/portugal#5" className="nav-link portugal" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/05</span>Our
                                    Portugal Office</NavLink>
                                <NavLink to="/career#6" className="nav-link career" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/06</span>We're
                                    Hiring</NavLink>
                                <NavLink to="/contact#7" className="nav-link contact" activeclassname="active"
                                         onClick={() => this.handleMenu()}><span>/07</span>Contact
                                    Us</NavLink>
                            </Container>
                            <div className="header-footer">
                                <span className="modalButton"
                                      onClick={this.props.openLegalMentions}>Legal Mentions</span>
                            </div>
                        </BrowserView>
                        <MobileView className="navbar-mobile container navbar__wrapper">
                            <NavLink to="/#top" className="nav-link home" activeclassname="active"
                                     onClick={() => this.handleMenu("1")}><span>/01</span>Home</NavLink>
                            <NavLink to="/about#2" className="nav-link about" activeclassname="active"
                                     onClick={() => this.handleMenu("2")}><span>/02</span>Who we
                                are</NavLink>
                            <NavLink to="/offering#3" className="nav-link offering" activeclassname="active"
                                     onClick={() => this.handleMenu("3")}><span>/03</span>What
                                We're Offering</NavLink>
                            <NavLink to="/cyprus#4" className="nav-link cyprus" activeclassname="active"
                                     onClick={() => this.handleMenu("4")}><span>/04</span>Our
                                Cyprus Office</NavLink>
                            <NavLink to="/portugal#5" className="nav-link portugal" activeclassname="active"
                                     onClick={() => this.handleMenu("5")}><span>/05</span>Our
                                Portugal Office</NavLink>
                            <NavLink to="/career#6" className="nav-link career" activeclassname="active"
                                     onClick={() => this.handleMenu("6")}><span>/06</span>We're
                                Hiring</NavLink>
                            <NavLink to="/contact#7" className="nav-link contact" activeclassname="active"
                                     onClick={() => this.handleMenu("7")}><span>/07</span>Contact
                                Us</NavLink>
                            <div className="header-footer">
                                <span className="modalButton"
                                      onClick={this.props.openLegalMentions}>Legal Mentions</span>
                            </div>
                        </MobileView>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Header;
