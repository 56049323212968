import React, { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div className={`side_image side_image-${this.props.sideImage}`}/>
                <div className="contact-page page">
                    <div className="contact__content page__content visible-content">
                        <div className="page_number">
                            /07
                        </div>
                        <h2>Say hello to our team.</h2>
                        <div className="page_description">
                            <p>
                                If you are interested in our services, don’t hesitate to contact us today.<br/>
                                We will get back to you at the earlier possible convenience.
                            </p>

                            <a href="mailto:contact@tlfe.com"> contact@tlfe.com </a>
                        </div>
                        <a href="mailto:contact@tlfe.com" className="btn">
                            <div className="front">Write an email</div>
                            <div className="back">Write an email</div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
