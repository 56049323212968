import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cross from "../images/cross.svg";

class Career extends Component {

    componentWillUnmount() {
        if (this.props.isSecondCardOpen) {
            this.props.toggleCard("card2");
        }
        const newParent = document.body;
        const bambooResult = document.getElementById('BambooHR');
        newParent.append(bambooResult);
        document.getElementById('BambooHR').setAttribute('style', 'display: none');
    }

    componentDidMount() {
        const newParent = document.getElementById('bamboo-target');
        const bambooResult = document.getElementById('BambooHR');
        newParent.prepend(bambooResult);
        document.getElementById('BambooHR').removeAttribute('style');
        setTimeout(() => {
            const bambooLinks = document.querySelectorAll(".BambooHR-ATS-Jobs-Item a");
            bambooLinks.forEach((link) => {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            });
        }, 2000);
    }

    render() {
        return (
            <div data-id={`${this.props.sideImage}`} className="swiper-slide">
                <div
                    className={`side_image side_image-${this.props.sideImage} ${this.props.isSecondCardOpen ? "side_image-opened-page" : null}`}/>
                <div className="career-page page">
                    <div
                        className={`career__content page__content visible-content ${!this.props.isSecondCardOpen ? "show" : "hide"}`}>
                        <div className="page__content_container">
                            <div className="page_number">
                                /06
                            </div>
                            <h2>We’re hiring!</h2>
                            <div className="page_description">
                                <p>
                                    Looking for a new challenge in your career?<br/>
                                    Have a look at our current opportunities in trendy Lisbon and sunny Cyprus,
                                    and join our growing team.
                                </p>
                                <span className="openServices info-btn" onClick={() => this.props.toggleCard("card2")}>[ Discover open positions ]</span>
                            </div>
                            <Link to="/contact" className="btn">
                                <div className="front">Contact us</div>
                                <div className="back">Contact us</div>
                            </Link>
                        </div>
                        <div className={`positions read-more ${this.props.isSecondCardOpen ? "show" : "hide"}`}>
                            <div id="bamboo-target" className="positions__container">
                                <Link to="/contact" className="btn">
                                    <div className="front">Contact us</div>
                                    <div className="back">Contact us</div>
                                </Link>
                            </div>
                            <div className="close_button" onClick={() => this.props.toggleCard("card2")}>
                                <img src={cross} alt="cross"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Career;
