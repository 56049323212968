import React, { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            <div id="7" data-id="7" className={`swiper-slide`}>
                <div className={"side_image side_image-7"}>
                    <div className="page_number">
                        /07
                    </div>
                </div>
                <div className="contact-page page">
                    <div className="contact__content page__content visible-content">
                        <h2>Say hello to our team.</h2>
                        <div className="page_description">
                            <p>
                                If you are interested in our services, don’t hesitate to contact us today.<br/>
                                We will get back to you at the earlier possible convenience.
                            </p>

                            <a href="mailto:contact@tlfe.com"> contact@tlfe.com </a>
                        </div>
                        <a href="mailto:contact@tlfe.com" className="btn">
                            <div>Write an email</div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
