import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div id="2" data-id="2" className="swiper-slide">
                <div className={"side_image side_image-2"}>
                    <div className="page_number">
                        /02
                    </div>
                </div>
                <div className="whoweare-page page">
                    <div className="whoweare__content page__content visible-content">
                        <h2>Who we are?</h2>
                        <div className="page_description">
                            <p>
                                TLFE are the leaders in offering companies online services in Marketing and Advertising,
                                Customer
                                Support, Customer Retention and Data Analysis.
                            </p>
                            <p>
                                We offer our clients a range of interactive marketing solutions that combine Data
                                Management and
                                Analytics using various metrics to measure the performance of the different initiatives.
                            </p>
                            <p>
                                We work closely with our clients to create targeted content, increase website traffic
                                and gain
                                new customers the most effective way.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;
